import React, { useState } from 'react';
import { Editor, EditorState, RichUtils, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button } from '@mui/material';
import { addBorderRules } from 'ckeditor5';

export default function RichTextEditor({ editorState, setEditorState, editText, ...other }) {
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [stateH1, setStateH1] = useState(false);
  const [stateH2, setStateH2] = useState(false);
  const [stateBlockquote, setStateBlockquote] = useState(false);
  const [stateBold, setStateBold] = useState(false);
  const [stateItalic, setStateItalic] = useState(false);
  const [stateUnderline, setStateUnderline] = useState(false);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleBlockType = (blockType) => {
    if (blockType === 'header-one') {
      setStateH1(!stateH1);
      setStateH2(false);
      setStateBlockquote(false);
    } else if (blockType === 'header-two') {
      setStateH1(false);
      setStateH2(!stateH2);
      setStateBlockquote(false);
    } else if (blockType === 'blockquote') {
      setStateH1(false);
      setStateH2(false);
      setStateBlockquote(!stateBlockquote);
    }
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  const toggleH1 = () => {
    setStateH1(!stateH1);
  };
  const toggleH2 = () => {
    setStateH2(!stateH2);
  };
  const toggleBold = () => {
    setStateBold(!stateBold);
  };
  const toggleBlockquote = () => {
    setStateBlockquote(!stateBlockquote);
  };
  const toggleUnderline = () => {
    setStateUnderline(!stateUnderline);
  };
  const toggleItalic = () => {
    setStateItalic(!stateItalic);
  };

  return (
    <div>
      <div className="toolbar">
        <Button
          variant={stateH1 ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleBlockType('header-one');
            toggleH1();
          }}
        >
          H1
        </Button>
        <Button
          variant={stateH2 ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleBlockType('header-two');
            toggleH2();
          }}
        >
          H2
        </Button>
        <Button
          variant={stateBlockquote ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleBlockType('blockquote');
            toggleBlockquote();
          }}
        >
          Blockquote
        </Button>
        <Button
          variant={stateBold ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleInlineStyle('BOLD');
            toggleBold();
          }}
        >
          Bold
        </Button>
        <Button
          variant={stateItalic ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleInlineStyle('ITALIC');
            toggleItalic();
          }}
        >
          Italic
        </Button>
        <Button
          variant={stateUnderline ? 'contained' : 'outlined'}
          onMouseDown={(e) => {
            e.preventDefault();
            toggleInlineStyle('UNDERLINE');
            toggleUnderline();
          }}
        >
          Underline
        </Button>
      </div>
      <div className="editor">
        <Editor
          addBorderRules
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
          readOnly={!editText}
          {...other}
        />
      </div>
    </div>
  );
}
