import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
// @mui
import {
  Box,
  Grid,
  Card,
  Badge,
  Stack,
  Alert,
  Button,
  Avatar,
  Snackbar,
  Container,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Editor, EditorState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
// components
import Iconify from '../../../components/iconify';
// hooks
import useFetch from '../../../hooks/useFetch';
// api
import axios from '../../../api/axios';
import { RichTextEditor } from '../../../components/hook-form';

export default function ConditionPage() {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorStateEnglish, setEditorStateEnglish] = useState(() => EditorState.createEmpty());
  const [editorStateArabic, setEditorStateArabic] = useState(() => EditorState.createEmpty());

  const theme = useTheme();

  const methods = useForm();
  // const onSubmit = data => console.log(data);

  const navigate = useNavigate();
  let USERLIST = [];
  const { data, loading, error } = useFetch('/api/condition');

  if (data) {
    // const htmlContent = data.text;
    // const contentState = stateFromHTML(htmlContent);
    // setEditorState(EditorState.createWithContent(contentState));
    USERLIST = data;
  }

  useEffect(() => {
    if (data) {
      setEditorState(EditorState.createWithContent(stateFromHTML(data[0]?.text)));
      setEditorStateEnglish(EditorState.createWithContent(stateFromHTML(data[0]?.text_english)));
      setEditorStateArabic(EditorState.createWithContent(stateFromHTML(data[0]?.text_arabic)));
    }
  }, [data]);

  const arabicRegex = /^[\u0600-\u06FF\s]*$/;

  const [text, setText] = useState(null);
  const [textEnglish, setTextEnglish] = useState(null);
  const [textArabic, setTextArabic] = useState(null);
  const [editText, setEditText] = useState(false);
  const [editTextEnglish, setEditTextEnglish] = useState(false);
  const [editTextArabic, setEditTextArabic] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [hoverText, setHoverText] = useState(false);
  const [hoverTextEnglish, setHoverTextEnglish] = useState(false);
  const [hoverTextArabic, setHoverTextArabic] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [res, setRes] = useState(null);
  const [err, setErr] = useState(null);
  const [open, setOpen] = useState(false);

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);

    axios
      .put(
        `api/condition/${USERLIST[0].id}`,
        clean({
          text: stateToHTML(editorState.getCurrentContent()),
          text_english: stateToHTML(editorStateEnglish.getCurrentContent()),
          text_arabic: stateToHTML(editorStateArabic.getCurrentContent()),
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then((res) => {
        setRes(res);
        setErr(null);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setErr(error);
        setRes(null);
      })
      .finally(() => {
        setOpen(true);
      });

    setIsSubmitting(false);
  };

  const handleEditText = () => {
    setEditText(!editText);
  };
  const handleEditTextEnglish = () => {
    setEditTextEnglish(!editTextEnglish);
  };
  const handleEditTextArabic = () => {
    setEditTextArabic(!editTextArabic);
  };

  const handleMouseEnter = () => {
    setHoverText(true);
  };
  const handleMouseEnterEnglish = () => {
    setHoverTextEnglish(true);
  };
  const handleMouseEnterArabic = () => {
    setHoverTextArabic(true);
  };

  const handleMouseLeave = () => {
    setHoverText(false);
  };
  const handleMouseLeaveEnglish = () => {
    setHoverTextEnglish(false);
  };
  const handleMouseLeaveArabic = () => {
    setHoverTextArabic(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    if(!editText){
      setEditorState(EditorState.createWithContent(stateFromHTML(USERLIST[0]?.text)));
    }

  },[editText])

  useEffect(()=>{
    if(!editTextEnglish){
      setEditorStateEnglish(EditorState.createWithContent(stateFromHTML(USERLIST[0]?.text_english)));
    }

  },[editTextEnglish])

  useEffect(()=>{
    if(!editTextArabic){
      setEditorStateArabic(EditorState.createWithContent(stateFromHTML(USERLIST[0]?.text_arabic)));
    }

  },[editTextArabic])

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : USERLIST ? (
    <>
      <Helmet>
        <title> Condition d'utilisation </title>
      </Helmet>

      <Container>
      <Typography variant="h4" gutterBottom>
            Condition d'utilisation
          </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {err ? (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  Erreur
                </Alert>
              </Snackbar>
            ) : (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Modification avec succès.
                </Alert>
              </Snackbar>
            )}
            <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
              {/* <div dangerouslySetInnerHTML={{__html: USERLIST[0].text}}/> */}

              
              <Grid container spacing={2} columns={16}>
                <Grid xs={8}>
                  <>
                    <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                      <Button variant="contained" color="warning" onClick={() => handleEditText()}>
                        {' '}
                        {!editText ? 'Editer' : 'Cancel'}{' '}
                      </Button>
                    </Stack>
                    <RichTextEditor editorState={editorState} setEditorState={setEditorState} editText={editText} />
                    {/* <TextField
                      id="outlined-textarea"
                      fullWidth
                      rows={19}
                      multiline
                      // defaultValue={EditorState.createWithContent((stateFromHTML(USERLIST[0]?.text))) }
                      value={text}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                    /> */}
                  </>
                </Grid>
                <Grid xs={8} spacing={2}>
                  <>
                    <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                      <Button variant="contained" color="warning" onClick={() => handleEditTextEnglish()}>
                        {' '}
                        {!editTextEnglish ? 'Editer' : 'Cancel'}{' '}
                      </Button>
                    </Stack>
                    <RichTextEditor editorState={editorStateEnglish} setEditorState={setEditorStateEnglish} editText={editTextEnglish} />
                   
                  </>
                </Grid>
              
                <Grid xs={16}>
                <>
                    <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                      <Button variant="contained" color="warning" onClick={() => handleEditTextArabic()}>
                        {' '}
                        {!editTextArabic ? 'Editer' : 'Cancel'}{' '}
                      </Button>
                    </Stack>
                    <RichTextEditor editorState={editorStateArabic} setEditorState={setEditorStateArabic} editText={editTextArabic} textAlignment="right"/>
                   
                  </>
                </Grid>
              </Grid>
            </Box>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}
