import { isNull } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import axios from '../api/axios';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState('');
  // localStorage.setItem("user",null);
  const navigate = useNavigate();

  const csrf = () => axios.get('/sanctum/csrf-cookie');

  const getUser = async () => {
    
    const { data } = await axios.get('/api/user');
    setUser(data);
  };



  const login = async ({ ...data }) => {
    
    setErrors('');
    try {
       
      await axios.post("/login", data);
      console.log('here')
      await getUser();
      console.log('here1')
      localStorage.setItem('user', 'USER');
        <Navigate to="/" />;
    } catch (e) {
      console.log(e);
      localStorage.removeItem('user');
      if (e.response.status === 422) {
        setErrors('email ou mot de passe incorrect');
      }
    }
  };

  // const login = async ({ ...data }) => {
  //   // axios.post("/logout").then(() => {
  //   //   console.log("done")
  //   //   setUser(null);
  //   //   localStorage.removeItem("user");
  //   // });

  //   // const res = await csrf();
  //   // console.log(res)

  //   setErrors('');

 
  //   try {
  //     await csrf();
      
  //       console.log('here');
  //       await axios.post('/login', data).then((res)=>{
  //         console.log(res)
  //       });
  //       console.log('here1');
  //     //  console.log(await axios.get('/api/hero')) ;
  //       await getUser();
  //       console.log('here2');
  //       localStorage.setItem('user', 'USER');
  //       <Navigate to="/" />;
      
  //   } catch (e) {
  //     console.log(e);
  //     localStorage.removeItem('user');
  //     if (e.response?.status === 422) {
  //       setErrors('email ou mot de passe incorrect');
  //     }
  //   }
  // };

  const register = async ({ ...data }) => {
    await csrf();
    setErrors([]);
    try {
      await axios.post('/register', data);
      await getUser();
      navigate('/');
    } catch (e) {
      if (e.response.status === 422) {
        setErrors('email ou mot de passe incorrect');
      }
    }
  };

  const logout = () => {
    console.log('here');
    axios.post('/logout').then(() => {
      setUser(null);
      localStorage.removeItem('user');
      // navigate('/');
    });
  };

  useEffect(() => {
    if (user === null) {
      getUser();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, user, errors, getUser, login, register, logout, csrf }}>
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
