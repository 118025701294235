import * as Yup from 'yup';
import { useState, useRef } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import {  Context, Bold, Essentials, Italic, Paragraph, ContextWatchdog } from 'ckeditor5';
// import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// // import 'ckeditor5/ckeditor5.css';
import { Editor, EditorState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect, RHFCheckbox, RHFRadio } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

export default function FilialeForm({ membre }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(membre || 'is_text');

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required('titre obligatoire'),
    title_english: Yup.string().required('titre obligatoire'),
    title_arabic: Yup.string().required('titre obligatoire'),
    text: Yup.string().required('text obligatoire'),
    text_english: Yup.string().required('text obligatoire'),
    text_arabic: Yup.string().required('text obligatoire'),
    image:membre? Yup.mixed().notRequired():Yup.mixed().required('Image obligatoire').test('fileExist',"Image obligatoire",(value)=>{
      return  value && value?.name
    })
    .test('type',"Image extension invalid",(value)=>{
     
      return value && (value.type==="image/png" || value.type==="image/jpeg")
    })
    .test('fileSize',"La taille de fichier est trop volumineux",(value)=>{ 
      return value && value.size <= 2000000
    }),
    // .test('type', 'Image extension invalid', (value) => {
    //   return value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
    //   ||(value.type === 'video/gif' || value.type === 'video/mp4' || value.type === 'video/mov');
    // })
    // .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
    //   return value && value.size <= 2000000;
    // }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    title: membre ? membre.title : '',
    title_english: membre ? membre.title_english : '',
    title_arabic: membre ? membre.title_arabic : '',
    text: membre ? membre.text : '',
    text_english: membre ? membre.text_english : '',
    text_arabic: membre ? membre.text_arabic : '',
    image: membre ? membre.image : null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleChangeType = (event) => {
    setType(event.target.value);
    console.log(type);
  };
  const onSubmit = async (values) => {
    // setLoading(true)
    //     axios
    //       .post(`api/clientQuote`, clean(values), { mode: 'no-cors' })
    //       .then((res) => {
    //         setResponse(res);
    //         setError(null);
    //         window.location.reload(false);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         setError(error);
    //         setResponse(null);
    //       })
    //       .finally(() => {
    //         setOpen(true);
    //         setLoading(false)
    //       });

    if (membre) {
      axios
        .put(`api/filiale/${membre.id}`, clean(values), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          setResponse(res);
          setError(null);
         
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    } else {
      axios
        .post('api/filiale', values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Question existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            La question a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="title" label="Titre" />
        <RHFTextField name="title_english" label="Titre en anglais" />
        <RHFTextField name="title_arabic" label="Titre en arabe" />
        <RHFTextField name="text" label="Text" multiline rows={4} />
        <RHFTextField name="text_english" label="Text en anglais" multiline rows={4} />
        <RHFTextField name="text_arabic" label="Text en arabe" multiline rows={4} />
        <RHFFile name="image" label="Image" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
