import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect, RHFCheckbox } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------
export default function BlogForm({ membre, question }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    title: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    text: membre ? Yup.string() : Yup.string().required('text obligatoire'),
    text_english: membre ? Yup.string() : Yup.string().required('text obligatoire'),
    title_english: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    text_arabic: membre ? Yup.string() : Yup.string().required('text obligatoire'),
    title_arabic: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    isFirst: Yup.bool(),
    isQFP: Yup.bool(),
    image: membre
      ? Yup.mixed().notRequired()
      : Yup.mixed()
          .test('fileExist', 'Image obligatoire', (value) => {
            return value && value?.name;
          })
          .test('type', 'Image extension invalid', (value) => {
            return (
              value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
            );
          })
          .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
            return value && value.size <= 200;
          }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    title: membre ? membre.title : '',
    text: membre ? membre.text : '',
    isFirst: membre ? membre.isFirst : false,
    isQFP: membre ? membre.isQFP : false,
    title_english: membre ? membre.title_english : '',
    text_english: membre ? membre.text_english : '',
    title_arabic: membre ? membre.title_arabic : '',
    text_arabic: membre ? membre.text_arabic : '',
    image:  null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    values.question_id = question || 1;
    values.isFirst = values.isFirst ? 1 : 0;
    values.isQFP =  0;
    if (membre) {
      if (values.image != null) {
        axios
          .post('api/newsFile', { image: values.image }, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);
            // setImage(res.data);
            // console.log(res.data);
            // window.location.reload(false);
          })
          .catch((error) => {
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            values.image = `img/news/${values.image.name}`;

            const val = clean(values);
            axios
              .put(`api/news/${membre.id}`, val, { headers: { 'Content-Type': 'application/json' } })
              .then((res) => {
                setResponse(res);
                setError(null);
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                setError(error);
                setResponse(null);
              })
              .finally(() => {
                setOpen(true);
              });
          });
      } else {
        delete values.image;
        const val = clean(values);
        axios
          .put(`api/news/${membre.id}`, val, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
      }
    } else {
      axios
        .post('api/news', values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Événement existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            L'événement' a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="title" label="Titre" />
        <RHFTextField name="text" label="Text" rows={8} multiline />
        <RHFTextField name="title_english" label="Titre anglais" />
        <RHFTextField name="text_english" label="Text anglais" rows={8} multiline />
        <RHFTextField name="title_arabic" label="Titre arab" dir="rtl" />
        <RHFTextField name="text_arabic" label="Text arab" rows={8} multiline dir="rtl" />
        <RHFCheckbox name="isFirst" label="Définir comme premiere actualité" />
        <RHFCheckbox name="isQFP" label="Ajouter dans les questions fréquement posèes" />

        <RHFFile name="image" label="Image" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
