import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Snackbar,
  Alert,
  Grid,
  TextField,
  Skeleton,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
// hooks
import useFetch from '../../../hooks/useFetch';
// api
import axios from '../../../api/axios';
import FilialeForm from '../../../sections/@dashboard/filiale/FilialeForm';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Titre', alignRight: false },
  { id: 'sub_title', label: 'Sous titre', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'title_english', label: 'Titre anglais', alignRight: false },
  { id: 'sub_title_english', label: 'Sous titre anglais', alignRight: false },
  { id: 'description_english', label: 'Description anglais', alignRight: false },
  { id: 'title_arabic', label: 'Titre arab', alignRight: false },
  { id: 'sub_title_arabic', label: 'Sous titre arab', alignRight: false },
  { id: 'description_arabic', label: 'Description arab', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: '', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FilialePage() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/hero/edit/${deletedItemId}`, { state: { name: deletedItemId } });
  };

  const { data, loading, error } = useFetch('/api/filiale');

  // if (data) {
  //   USERLIST = [
  //     {
  //       id: 0,
  //       title: 'SAROUSSA AUTO',
  //       text: "Fondée en 2002, Saroussa Auto, première société du groupe Saroussa, se distingue en tant que leader spécialisé dans l'importation et la distribution de pièces de rechange, de carrosserie automobile, ainsi que de produits de nettoyage et d'entretien. Avec son engagement envers l'excellence, SAROUSSA auto contribue à assurer la fiabilité et la satisfaction des clients en fournissant des pièces conformes aux normes de l'industrie.",
  //       image: '',
  //     },
  //     {
  //       id: 1,
  //       title: 'SALMI AUTO PARTS',
  //       text: "Fondée en 2015 en tant que filiale d'expansion de la société mère Saroussa, Salmi Auto-Parts se spécialise dans l'importation de pièces de rechange et de carrosserie automobile. Depuis sa création, l'entreprise s'est positionnée comme un acteur majeur en fournissant des produits de qualité, répondant aux besoins croissants du marché automobile Algérien.",
  //       image: '',
  //     },
  //     {
  //       id: 2,
  //       title: 'IKSAN',
  //       text: "Iksan, la deuxième filiale de Saroussa créée en 2021, se positionne en tant qu'expert spécialisé dans l'importation de pièces de rechange et de carrosserie automobile. Cette extension stratégique de la société mère vise à renforcer davantage la présence de Saroussa sur le marché en offrant de",
  //       image: '',
  //     },
  //     {
  //       id: 0,
  //       title: 'SAROUSSA PRO',
  //       text: "En 2023, le groupe Saroussa a élargi son influence avec la création de Pro Saroussa, une filiale spécialisée dans l'importation et la distribution de produits de nettoyage et d'entretien automobile ABRO.",
  //       image: '',
  //     },
  //   ];
  // }
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openSnack, setOpenSnack] = useState(false);

  const [openAddElementDialog, setOpenAddElementDialog] = useState(false);

  const [deletedItem, setDeletedItem] = useState(null);

  const [editedItem, setEditedItem] = useState(null);

  const [deletedItemId, setDeletedItemId] = useState(null);

  const [nbrElement, setNbrElement] = useState(4);

  const [USERLIST, setUSERLIST] = useState([]);

  const [language, setLanguage] = useState('fr');
  // const [USERLIST, setUSERLIST] = useState([
  //   {
  //     id: 0,
  //     title: 'SAROUSSA AUTO',
  //     text: "Fondée en 2002, Saroussa Auto, première société du groupe Saroussa, se distingue en tant que leader spécialisé dans l'importation et la distribution de pièces de rechange, de carrosserie automobile, ainsi que de produits de nettoyage et d'entretien. Avec son engagement envers l'excellence, SAROUSSA auto contribue à assurer la fiabilité et la satisfaction des clients en fournissant des pièces conformes aux normes de l'industrie.",
  //     image: '',
  //   },
  //   {
  //     id: 1,
  //     title: 'SALMI AUTO PARTS',
  //     text: "Fondée en 2015 en tant que filiale d'expansion de la société mère Saroussa, Salmi Auto-Parts se spécialise dans l'importation de pièces de rechange et de carrosserie automobile. Depuis sa création, l'entreprise s'est positionnée comme un acteur majeur en fournissant des produits de qualité, répondant aux besoins croissants du marché automobile Algérien.",
  //     image: '',
  //   },
  //   {
  //     id: 2,
  //     title: 'IKSAN',
  //     text: "Iksan, la deuxième filiale de Saroussa créée en 2021, se positionne en tant qu'expert spécialisé dans l'importation de pièces de rechange et de carrosserie automobile. Cette extension stratégique de la société mère vise à renforcer davantage la présence de Saroussa sur le marché en offrant de",
  //     image: '',
  //   },
  //   {
  //     id: 3,
  //     title: 'SAROUSSA PRO',
  //     text: "En 2023, le groupe Saroussa a élargi son influence avec la création de Pro Saroussa, une filiale spécialisée dans l'importation et la distribution de produits de nettoyage et d'entretien automobile ABRO.",
  //     image: '',
  //   },
  // ]);
  const [USERLIST2, setUSERLIST2] = useState([]);

  const handleChangeNombreElement = (e) => {
    setNbrElement(e.target.value);
    console.log(20 / nbrElement);
  };
  useEffect(() => {
    if (data) {
      setUSERLIST([...data]);
      setUSERLIST2([...data]);
    }
  }, [data]);
  useEffect(() => {
    if (nbrElement > 0) {
      const diff = USERLIST2.length % nbrElement;
      const add = nbrElement - diff;

      const added = new Array([]);
      let i = 0;
      while (i < add - 1) {
        added.push({ id: USERLIST.length + i, title: '', text: '', image: '' });
        i += 1;
      }
      // for(let i =0;i<add;i+1){
      //   added.push(
      //     {id:USERLIST.length+i,
      //       title:"",
      //       text:"",
      //       image:""
      //     }
      //   )
      // }
      if (added.length > 0) {
        setUSERLIST([...USERLIST2, ...added]);
      }
    }
  }, [USERLIST2]);
  useEffect(() => {
    if (nbrElement > 0) {
      const diff = USERLIST2.length % nbrElement;
      const add = nbrElement - diff;

      const added = new Array([]);
      let i = 0;
      while (i < add - 1) {
        added.push({ id: USERLIST.length + i, title: '', text: '', image: '' });
        i += 1;
      }
      // for(let i =0;i<add;i+1){
      //   added.push(
      //     {id:USERLIST.length+i,
      //       title:"",
      //       text:"",
      //       image:""
      //     }
      //   )
      // }
      if (added.length > 0) {
        setUSERLIST([...USERLIST2, ...added]);
      }
    }
  }, [nbrElement]);

  const handleOpenAddElementDialog = () => {
    setOpenAddElementDialog(true);
  };

  const handleCloseAddElementDialog = () => {
    setOpenAddElementDialog(false);
  };

  const handleOpenMenu = (event, name, id) => {
    setDeletedItem(name);
    setDeletedItemId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickDelete = () => {
    console.log(deletedItemId);
    axios
      .delete(`api/hero/${deletedItemId}`)
      .then()
      .catch((error) => {})
      .finally(() => {
        setOpenSnack(true);
        if (!error) {
          window.location.reload(false);
        }
      });

    // handleCloseDialog();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : USERLIST ? (
    <>
      <Helmet>
        <title> Filiale </title>
      </Helmet>
      <Dialog open={openAddElementDialog} onClose={handleCloseAddElementDialog} fullWidth>
        <DialogTitle>Ajouter un nouveau element</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FilialeForm membre={editedItem} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Filiale
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleOpenAddElementDialog();
            }}
            state={{ from: 'occupation' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nouveau element
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            pageName="hero"
          /> */}
          <TextField
            id="outlined-basic"
            label="Nombre d'élement par ligne"
            variant="outlined"
            value={nbrElement}
            onChange={(e) => {
              handleChangeNombreElement(e);
            }}
          />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="fr"
              onChange={(e) => {
                setLanguage(e.target.value);
                console.log(language);
              }}
            >
              <FormControlLabel value="fr" control={<Radio />} label="Fr" />
              <FormControlLabel value="en" control={<Radio />} label="En" />
              <FormControlLabel value="ar" control={<Radio />} label="Ar" />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 20 }}>
          {USERLIST.map((filiale, index) => (
            <Grid item xs={2} sm={4} md={20 / nbrElement} key={index}>
              {filiale.text && filiale.text.length > 0 ? (
                <Element
                  id={filiale.id}
                  title={filiale.title}
                  text={filiale.text}
                  title_english={filiale.title_english}
                  text_english={filiale.text_english}
                  title_arabic={filiale.title_arabic}
                  text_arabic={filiale.text_arabic}
                  image={filiale.image}
                  editedItem={setEditedItem}
                  handleOpenAddElementDialog={handleOpenAddElementDialog}
                  language={language}
                />
              ) : (
                <Card
                  onClick={() => {
                    setEditedItem(null);
                    handleOpenAddElementDialog();
                  }}
                  sx={{
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                >
                  {/* <Skeleton variant="rounded" width={210} height={60} />
                  <Skeleton variant="rectangular"  />
                  <Skeleton variant="rectangular"  /> */}
                  <Iconify icon="eva:plus-fill" width={50} color="gray" />
                </Card>
              )}
            </Grid>
          ))}
        </Grid>

        {/* <!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-ER54L201H2"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ER54L201H2');
</script> 
G-ER54L201H2
8585031687
*/}
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}

function Element({
  id,
  title,
  text,
  title_english: titleEnglish,
  text_english: textEnglish,
  title_arabic: titleArabic,
  text_arabic: textArabic,
  image,
  editedItem,
  handleOpenAddElementDialog,
  language,
}) {
  return (
    <Card
      sx={{
        padding: '20px',
        height: '100%',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      onClick={() => {
        editedItem({ id, title, text, title_english: titleEnglish, text_english: textEnglish, title_arabic: titleArabic, text_arabic: textArabic, image });
        handleOpenAddElementDialog();
      }}
    >
      <img src={`https://lab.saroussa.com/filiale/${image}`} alt={title}/> 
      <Typography variant="h6" align={'justify'}>
        {language === 'fr' ? title : language === 'en' ? titleEnglish : titleArabic}
      </Typography>
      <Typography variant="paragraph">
        {language === 'fr' ? text : language === 'en' ? textEnglish : textArabic}
      </Typography>
    </Card>
  );
}
