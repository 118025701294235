import { Navigate, useRoutes, Route, Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import AuthLayout from './layouts/AuthLayout';
import GuestLayout from './layouts/GuestLayout';
//
import BlogPage from './pages/blog/BlogPage';
import UserPage from './pages/user/UserPage';
import ClientPage from './pages/user/ClientPage';
import AddClientPage from './pages/user/AddClientPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/product/ProductsPage';
import AddProductPage from './pages/product/AddProductPage';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoryPage from './pages/CategoryPage';
import POS from './pages/pos/POSPage';
import AddPOSPage from './pages/pos/AddPOSPage';
import TeamPage from './pages/team/TeamPage';
import AddTeamPage from './pages/team/AddTeamPage';
import BrandPage from './pages/brand/BrandPage';
import ModalPage from './pages/brand/ModalPage';
import AddBrandPage from './pages/brand/AddBrandPage';
import AddModalPage from './pages/brand/AddModalPage';
import AddUserPage from './pages/user/AddUserPage';
import CommandPage from './pages/command/CommandPage';
import HeroPage from './pages/siteSections/hero/HeroPage';
import AddHeroPage from './pages/siteSections/hero/AddHeroPage';
import LogoPage from './pages/siteSections/logo/LogoPage';
import AddLogoPage from './pages/siteSections/logo/AddLogoPage';
import AddRelationPage from './pages/siteSections/logo/AddRelationPage';
import HistoriquePage from './pages/siteSections/historique/HistoriquePage';
import AddHistoriquePage from './pages/siteSections/historique/AddHistoriquePage';
import BossPage from './pages/siteSections/boss/BossPage';
import ClientQuotePage from './pages/siteSections/clientQuote/ClientQuotePage';
import AddClientQuotePage from './pages/siteSections/clientQuote/AddClientQuotePage';
import AddFAQPage from './pages/siteSections/FAQ/AddFAQPage';
import ProfilePage from './pages/ProfilePage';

// context
import useAuthContext from './context/AuthContext';
import MessagePage from './pages/message/MessagePage';
import QuestionPage from './pages/question/QuestionPage';
import AnswerPage from './pages/question/AnswerPage';
import PromotionPage from './pages/siteSections/promotion/PromotionPage';
import AddPromotionPage from './pages/siteSections/promotion/AddPromotionPage';
import UserHelpPage from './pages/siteSections/userHelp/UserHelpPage';
import AddUserHelpPage from './pages/siteSections/userHelp/AddUserHelpPage';
import FAQPage from './pages/siteSections/FAQ/FAQPage';
import ConditionPage from './pages/siteSections/condition/ConditionPage';
import PrivacyPolicyPage from './pages/siteSections/privacy&policy/PrivacyPolicyPage';
import BlogViewPost from './sections/@dashboard/blog/BlogViewPost';
import AddBlogPage from './pages/blog/AddBlogPage';
import FilialePage from './pages/siteSections/filiale/FilialePage';
import AddFilialePage from './pages/siteSections/filiale/AddFilialePage';
// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();
  // console.log(localStorage.getItem("token"));
  // const routes = useRoutes([
  //   localStorage.getItem("token")?
  //   {
  //     path: '/',
  //     element: <DashboardLayout />,
  //     children:[
        
  //       { element: <Navigate to="/app" />, index: true },
  //       { path: 'app', element: <DashboardAppPage /> },
  //       { path: 'user', element: <UserPage /> },
  //       { path: 'products', element: <ProductsPage /> },
  //       { path: 'blog', element: <BlogPage /> },
  //     ],
  //   }:
  //   {
  //     path: 'login',
  //     element: <LoginPage />,
  //   },
  //   {
  //     element: <SimpleLayout />,
  //     children: [
  //       { element: <Navigate to="/dashboard/app" />, index: true },
  //       { path: '404', element: <Page404 /> },
  //       { path: '*', element: <Navigate to="/404" /> },
  //     ],
  //   },
  //   {
  //     path: '*',
  //     element: <Navigate to="/404" replace />,
  //   },
  // ]);

  // return routes;
 
    return(
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<DashboardAppPage />} />
            {/* USER */}
          <Route path="/user" element={<UserPage />} />
          <Route path="/user/edit/:name" element={<AddUserPage />} />
          <Route path="/user/add" element={<AddUserPage />} />
            {/* CLIENT */}
          <Route path="/client" element={<ClientPage />} />
          <Route path="/client/edit/:name" element={<AddClientPage />} />
          <Route path="/client/add" element={<AddClientPage />} />
            {/* PRODUCT */}
          {/* <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/add" element={<AddProductPage />} />
          <Route path="/products/edit/:name" element={<AddProductPage />} /> */}
            {/* BLOG */}
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:name" element={<BlogViewPost />} />
          <Route path="/blog/add" element={<AddBlogPage />} />
          <Route path="/blog/edit/:name" element={<AddBlogPage />} />
            {/* CATEGORY */}
          <Route path="/category" element={<CategoryPage />} />
            {/* POS */}
          <Route path="/pos" element={<POS />} />
          <Route path="/pos/add" element={<AddPOSPage />} />
          <Route path="/pos/edit/:name" element={<AddPOSPage />} />
            {/* TEAM */}
          {/* <Route path="/team" element={<TeamPage />} />
          <Route path="/team/add/" element={<AddTeamPage />} />
          <Route path="/team/edit/:name" element={<AddTeamPage />} /> */}
            {/* BRAND */}
          {/* <Route path="/brand" element={<BrandPage />} />
          <Route path="/brand/add" element={<AddBrandPage />} />
          <Route path="/brand/edit/:name" element={<AddBrandPage />} /> */}
            {/* MODAL */}
          {/* <Route path="/modal" element={<ModalPage />} />
          <Route path="/modal/add" element={<AddModalPage />} />
          <Route path="/modal/edit/:name" element={<AddModalPage />} /> */}
            {/* COMMAND */}
          {/* <Route path="/command" element={<CommandPage />} /> */}
          {/* HERO */}
          <Route path="/hero" element={<HeroPage />} />
          <Route path="/hero/add" element={<AddHeroPage />} />
          <Route path="/hero/edit/:name" element={<AddHeroPage />} />
            {/* LOGO */}
          <Route path="/logo" element={<LogoPage />} />
          <Route path="/logo/add" element={<AddLogoPage />} />
          <Route path="/logo/edit/:name" element={<AddLogoPage />} />
          <Route path="/logo/relation/:name" element={<AddRelationPage />} />
            {/* HISTORIQUE */}
          <Route path="/historique" element={<HistoriquePage />} />
          <Route path="/historique/add" element={<AddHistoriquePage />} />
          <Route path="/historique/edit/:name" element={<AddHistoriquePage />} />
            {/* BOSS */}
          <Route path="/boss" element={<BossPage />} />
            {/* FAQ */}
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/faq/add" element={<AddFAQPage />} />
          <Route path="/faq/edit/:name" element={<AddFAQPage />} />
            {/* CONDITION */}
          <Route path="/condition" element={<ConditionPage />} />
          {/* PRIVACY&POLICY */}
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
            {/* CLIENTQUOTE */}
          <Route path="/temoignage" element={<ClientQuotePage />} />
          <Route path="/temoignage/add" element={<AddClientQuotePage />} />
          <Route path="/temoignage/edit/:name" element={<AddClientQuotePage />} />
            {/* PROFILE */}
          <Route path="/profile" element={<ProfilePage />} />
            {/* MESSAGE */}
          <Route path="/message" element={<MessagePage />} />
            {/* QUESTION */}
          <Route path="/question" element={<QuestionPage />} />
          {/* <Route path="/answer/:name" element={<AnswerPage />} /> */}
          {/* <Route path="/historique/add" element={<AddHistoriquePage />} />
          <Route path="/historique/edit/:name" element={<AddHistoriquePage />} /> */}
            {/* PROMOTION */}
          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/promotion/add" element={<AddPromotionPage />} />
          <Route path="/promotion/edit/:name" element={<AddPromotionPage />} />
            {/* USERHELP */}
          <Route path="/userHelp" element={<UserHelpPage />} />
          <Route path="/userHelp/add" element={<AddUserHelpPage />} />
          <Route path="/userHelp/edit/:name" element={<AddUserHelpPage />} />
            {/* FILIALE */}
          <Route path="/filiale" element={<FilialePage />} />
          <Route path="/filiale/add" element={<AddFilialePage />} />
          <Route path="/filiale/edit/:name" element={<AddFilialePage />} />
            {/* NOT FOUND */}
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Navigate to="/404" /> } />
        </Route>
        <Route element={<GuestLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Navigate to="/404" /> } />
        </Route>
      </Routes>
)
  

      
      
}





