import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  FAQForm  from '../../../sections/@dashboard/faq/FAQForm';
// hooks
import useFetch from '../../../hooks/useFetch';
// ----------------------------------------------------------------------




export default function AddFAQPage() {
  const location = useLocation();
  const { data: FAQ, loading, error } = useFetch(`/api/faq`,{params:{faqId:location.state.name}});

  return (
    FAQ?
    <>
      <Helmet>
        <title> FAQ </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location.state.name? `Modifier ${FAQ.question}` :"Ajouter un nouveau element"}
          </Typography>
        </Stack>
        <FAQForm membre={location.state.name?FAQ:null}/>
      </Container>
    </>
    : <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );

}



